<template>
  <div>
    <v-form v-model="form">
      <v-card>
        <v-card-title>
          BINBASE
        </v-card-title>
        <v-card-text>
            <v-row>
              <v-col
                cols=4
              >
                <v-switch 
                  v-model="Multi"
                  label="Multi ?"
                  color="green"
                />
                <br>
                <v-textarea
                  v-if="Multi"
                  v-model="bin"
                  label="Bin"
                  :rules="[required('Bin')]"
                />
                <br />
                <v-btn
                  v-if="Multi"
                  @click="searchBinMulti"
                  color="green"
                  :disabled="multiCheck"
                >
                  Check
                </v-btn>
                <v-text-field
                  v-if="!Multi"
                  v-model="bin"
                  label="Bin"
                  :rules="[required('Bin')]"
                  maxlength=6
                  @change="searchBin"
                />
                <loading v-if="Multi" :status="loader" />
              </v-col>
              <v-col
                cols=8
              >
                <template v-if="Multi">
                  <v-data-table
                    :headers="headers"
                    :items="itemsWithIndex"
                    :loading="multiCheck"
                  >
                    <template #[`item.country`]="{ item }">
                      <BinResult :item="item" k="country" />
                    </template>
                    <template #[`item.bank`]="{ item }">
                      <BinResult :item="item" k="bank" />
                    </template>
                    <template #[`item.level`]="{ item }">
                      <BinResult :item="item" k="level" />
                    </template>
                    <template #[`item.type`]="{ item }">
                      <BinResult :item="item" k="type" />
                    </template>
                    <template #[`item.countryCode`]="{ item }">
                      <BinResult :item="item" k="countryCode" />
                    </template>
                  </v-data-table>

                </template>
                <template v-if="!Multi">
                  <v-list-item class="grow">
                    <v-list-item-content>
                      <v-list-item-subtitle>Bin</v-list-item-subtitle>
                      <v-list-item-title>{{binData.bin || '...'}}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="grow">
                    <v-list-item-content>
                      <v-list-item-subtitle>Country</v-list-item-subtitle>
                      <v-list-item-title>{{binData.country || '...'}}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="grow">
                    <v-list-item-content>
                      <v-list-item-subtitle>Bank</v-list-item-subtitle>
                      <v-list-item-title>{{binData.bank || '...'}}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="grow">
                    <v-list-item-content>
                      <v-list-item-subtitle>Level</v-list-item-subtitle>
                      <v-list-item-title>{{binData.level || '...'}}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="grow">
                    <v-list-item-content>
                      <v-list-item-subtitle>Type</v-list-item-subtitle>
                      <v-list-item-title>{{binData.type || '...'}}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="grow">
                    <v-list-item-content>
                      <v-list-item-subtitle>Country Code</v-list-item-subtitle>
                      <v-list-item-title>{{binData.countryCode || '...'}}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
        </v-card-actions>
        <loading :status="loader" />
      </v-card>
    </v-form>
  </div>
</template>

<script>
import BinService from '@/modules/bin.js';
import validations from '@/helpers/validations.js';
import loading from '@/components/base/loading.vue';
import Datatables from '@/components/base/datatables.vue';
import BinResult from '@/components/bin.vue';
import {ThongBao,autoCardNum} from '@/helpers';
import {FindInArray} from '@/helpers/arrays.js';
import {setStore,getStore,removeItem} from '@/helpers/storage.js';
export default {
  components:{
    loading,
    Datatables,
    BinResult,
  },
  data () {
    return {
      Multi:true,
      form: false,
      bin: undefined,
      loader:false,
      binData:{},
      headers:[
        { text: 'Bin', align: 'center', sortable: true,value: 'bin',width:"10%",class:"primary secondary--text text-center" },
        { text: 'Country', align: 'center', sortable: true,value: 'country',width:"20%",class:"primary secondary--text text-center" },
        { text: 'Bank', align: 'center', sortable: true,value: 'bank',width:"30%",class:"primary secondary--text text-center" },
        { text: 'Level', align: 'center', sortable: true,value: 'level',width:"10%",class:"primary secondary--text text-center" },
        { text: 'Type', align: 'center', sortable: true,value: 'type',width:"10%",class:"primary secondary--text text-center" },
        { text: 'Country Code', align: 'center', sortable: true,value: 'countryCode',width:"10%",class:"primary secondary--text text-center" },
      ],
      items: [],
      autoLoader:undefined,
      multiCheck: false,
    }
  },
  computed:{
    itemsWithIndex(){
      return this.items.map((item,index)=>({
        ...item,
        index:index+1,
      }));
    },
    unChecked(){
      const uncheck = this.items.filter(v=>v.checked === false).length
      return (uncheck > 5) ? 5:uncheck;
    },
    unCheckedList(){
      let list = [];
      const listUnchecked = this.items.filter(v=>v.checked === false);
      if(listUnchecked.length === 0) return list;
      listUnchecked.map((item,index)=>{
        if(index > 5) return;
        list.push(item);
      }).filter(v=>!!v);
      return list;
    },
  },
  methods:{
    ...validations,
    checkBin({bin,vm}){
      const index = FindInArray(this.items,bin,'bin');
      vm.$set(vm.items[index],'checking',true);
      // setTimeout(()=>{
      //   vm.$set(vm.items[index],'checked',true);
      //   vm.$set(vm.items[index],'checking',false);
      // },1000);
      // return;
      return BinService.Check({bin:bin}).then(resp=>{
        const {data} = resp;
        if(data){
					if(data !== 404) {
						Object.keys(data).map(k => {
							vm.$set(vm.items[index], k, data[k]);
						})
					}
          vm.$set(vm.items[index],'checked',true);
        }
        vm.$set(vm.items[index],'checking',false);
      }).catch(()=>{
        vm.$set(vm.items[index],'checking',false);
      }).finally(()=>{
        vm.$set(vm.items[index],'checking',false);
      });
    },
    autoCheck(){
      if(this.unCheckedList.length > 0){
        let check = [];
        this.unCheckedList.map(item=>{
          const bin = item.bin;
          check.push(this.checkBin({bin,vm:this}));
        });
        Promise.all(check).finally(()=>{
          setTimeout(this.autoCheck,500);
        });
      }else{
        setStore('binbase',this.items);
        this.multiCheck = false;
        this.bin = undefined;
      }
    },
    searchBinMulti(){
      this.loader = true;
      const data = this.bin.split('\n');
      let newData = []; let exists = [];
      data.forEach(item=>{
        const cardNum = autoCardNum(item);
        if(cardNum !== undefined) {
          const bin = cardNum.substring(0,6);
          if(!exists.includes(bin)){
            exists.push(bin);
            newData.push({bin,checked:false});
          }
        }else if(!isNaN(item.trim()) && item.trim().length >= 6){
          const bin = item.trim().substring(0,6);
          if(!exists.includes(bin)){
            exists.push(bin);
            newData.push({bin,checked:false});
          }
        }
      })
      if(newData.length > 0) {
        this.items = newData;
        this.items[0].checking = true;
        this.multiCheck = true;
        removeItem('binbase');
        this.autoCheck();
      }
      this.loader = false;
    },
    searchBin(){
      if(this.bin){
        if(this.bin.length !== 6) return ThongBao.Error('Bin phải 6 số !!!');
        this.loader = true;
        this.binData = {};
        BinService.Check({bin:this.bin}).then(resp=>{
          const {data} = resp;
          if(data === false){
            ThongBao.Error('Máy chủ Bin đang lỗi !!!');
          }else if(data){
						if(data === 404){
							this.binData = {
								"country":"NOT FOUND","bank":"NOT FOUND","level":"NOT FOUND","bin":this.bin,"type":"NOT FOUND","brand":"NOT FOUND","countryCode":"NOT FOUND"
							}
						}else {
							this.binData = data;
						}
          }
        }).finally(()=>{
          this.loader = false;
          this.bin = undefined;
        });
      }
    },
  },
  mounted(){
    const binbase = getStore('binbase');
    if(binbase) this.items = binbase;
  }
}
</script>

<style>

</style>