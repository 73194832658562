export const Xoa = (array,index,max=1) => {
  return array.splice(index,max);
}
export const FindInArray = (array,find,get) =>{
  let found = undefined;
  array.map((item,index)=>{
    if(item[get] === find) found = index;
  })
  return found;
}
export const randomFromArray = (array) =>{
  return array[Math.floor(Math.random() * array.length)];
}
