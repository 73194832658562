<template>
  <div>
    <template v-if="!item.checking">
      <span class="text-body2" v-if="item.checked">{{item[k]}}</span>
      <span class="text-body2" v-else>...</span>
    </template>
    <span class="text-body2" v-else>
      <v-progress-circular
        indeterminate
        color="green"
        :size="20"
      />
    </span>
  </div>
</template>

<script>
export default {
  props: ['item','k'],

}
</script>